@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */

.theme-general {
  --danger: #e3342f;
  --success: #34d399;
  --accent: #00e676;
}

.theme-light {
  --primary: #f8f8f8;
  --secondary: #dbe1e8;
  --tertiary: #fff;
  --disabled: #d1d5db;
  --highlight: #f9fafb;
  --primary-text: #6b7280;
  --secondary-text: #454e56;
  --disabled-text: #6b7280;
  --trow: #F5F6F8;
}
.theme-dark {
  --primary: #12181b;
  --secondary: #2a2e35;
  --tertiary: #374151;
  --disabled: #6b7280;
  --highlight: #1f2937;
  --primary-text: #ffffff;
  --secondary-text: #b8b8b9;
  --disabled-text: #d1d5db;
  --trow: #F5F6F8;
}

@font-face {
  font-family: 'GlacialIndifference';
  src: url('/fonts/GlacialIndifference-Bold.otf');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: 'GlacialIndifference';
  src: url('/fonts/GlacialIndifference-Italic.otf');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'GlacialIndifference';
  src: url('/fonts/GlacialIndifference-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@layer components {
  /* .btn-blue {
    @apply py-2 px-4 bg-blue-500 font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  } */

  .disabled {
    @apply text-disabled-text bg-disabled;
  }

  .hoverable {
    @apply p-2 cursor-pointer rounded hover:shadow-lg hover:bg-primary hover:bg-opacity-50;
  }

  .btn {
    @apply text-white p-4 text-center rounded font-bold cursor-pointer hover:opacity-90;
  }

  .btn-async {
    @apply btn bg-success text-white;
  }

  .btn-async-pending {
    @apply btn bg-blue text-white cursor-default;
  }

  .btn-async-error {
    @apply btn bg-danger text-white cursor-pointer;
  }

  .standout {
    @apply p-4 border-primary shadow-md bg-secondary;
  }

  .standout-alt {
    @apply p-4 border-secondary shadow-md bg-tertiary;
  }

  .card {
    @apply rounded-sm border-primary shadow-md py-2 px-4 bg-tertiary;
  }

  .card-hover {
    @apply card hover:shadow-lg cursor-pointer hover:bg-highlight;
  }

  .card-disabled {
    @apply card bg-disabled text-disabled-text cursor-default;
  }

  .text-header {
    @apply text-2xl py-4 font-bold text-title;
  }

  .text-major {
    @apply text-base font-semibold text-primary-text;
  }
  .text-minor {
    @apply text-sm font-medium text-secondary-text;
  }

  .text-mini {
    @apply text-xs text-primary-text;
  }
  .text-small {
    @apply text-xs text-primary-text;
  }
  .text-normal {
    @apply text-sm text-primary-text;
  }
  .text-subtitle {
    @apply text-base text-primary-text ;
  }
  .text-title {
    @apply text-lg text-primary-text ;
  }
  .text-header {
    @apply text-xl text-primary-text ;
  }
  .avatar-stackable {
    @apply inline-block h-12 w-12 rounded-full ring-2 ring-primary;
  }

  .table-row {
    @apply bg-trow
  }
}
